<!--
* @description:
* @fileName shopManage.vue
* @author hvv
* @date 2022/01/13 15:21:21
!-->
<template>
  <div>
    <vab-query-form>
      <vab-query-form-left-panel :span="12">
        <el-button :icon="Plus" type="primary" @click="handleEdit($event)">
          添加
        </el-button>
        <!-- <el-button :icon="Delete" type="danger" @click="handleDelete($event)">
          批量删除
        </el-button> -->
      </vab-query-form-left-panel>
      <vab-query-form-right-panel :span="12">
        <el-form :inline="true" :model="queryForm" @submit.prevent>
          <el-form-item label="所属店铺">
            <el-select
              v-model="queryForm.shopId"
              placeholder="请选择店铺"
              @change="queryData"
            >
              <el-option
                v-for="item in shopList"
                :key="item.id"
                :label="item.shopName"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <!-- <el-form-item>
            <el-button :icon="Search" type="primary" @click="queryData">
              查询
            </el-button>
          </el-form-item> -->
        </el-form>
      </vab-query-form-right-panel>
    </vab-query-form>
    <el-table v-loading="listLoading" border :data="list">
      <el-table-column
        label="优惠券名称"
        prop="couponName"
        show-overflow-tooltip
      />
      <el-table-column
        label="开始时间"
        prop="startTime"
        show-overflow-tooltip
      />
      <el-table-column
        label="结束时间"
        prop="endTime"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column label="优惠券类型" show-overflow-tooltip>
        <template #default="{ row }">
          <el-tag v-if="row.couponType == 'FULL'">满减券</el-tag>
          <el-tag type="success" v-else>折扣券</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        label="描述"
        prop="description"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column label="操作" show-overflow-tooltip width="185">
        <template #default="{ row }">
          <el-button type="primary" @click="handleEdit(row)">
            <vab-icon icon="edit-2-line" />
            编辑
          </el-button>
          <el-button type="danger" @click="handleDelete(row)">
            <vab-icon icon="delete-bin-6-line" />
            删除
          </el-button>
        </template>
      </el-table-column>
      <template #empty>
        <!-- <el-image
                class="vab-data-empty"
                :src="require('@/assets/empty_images/data_empty.png')"
              /> -->
        <el-empty class="vab-data-empty" description="暂无数据" />
      </template>
    </el-table>
    <!-- <el-pagination
      background
      :current-page="queryForm.pageNumber"
      :layout="layout"
      :page-size="queryForm.pageSize"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    /> -->
  </div>
</template>

<script>
  import {
    defineComponent,
    defineAsyncComponent,
    getCurrentInstance,
    onMounted,
    reactive,
    toRefs,
    onActivated,
  } from 'vue'

  import { Delete, Plus, Search } from '@element-plus/icons'
  import { getShopList } from '@/api/shop'
  import { getCouponList, delCoupon } from '@/api/ticket'
  export default defineComponent({
    name: 'ticketList',
    components: {},
    setup() {
      const { proxy } = getCurrentInstance()

      const state = reactive({
        queryShop: { shopName: '', pageNumber: 1, pageSize: 999 },
        queryForm: { shopId: 0, pageNumber: 1, pageSize: 20 },
        total: 0,
        shopList: [],
        listLoading: false,
        list: [],
        layout: 'prev, pager, next',
        editRef: null,
      })

      const handleEdit = (row) => {
        if (row.id) {
          state.editRef.showEdit(row)
        } else {
          state.editRef.showEdit()
        }
      }

      const handleDelete = (row) => {
        if (row.id) {
          proxy.$baseConfirm('你确定要删除当前项吗', null, async () => {
            const { msg } = await delCoupon(row.id)
            proxy.$baseMessage(`操作成功`, 'success', 'vab-hey-message-success')
            await fetchData()
          })
        }
      }

      const queryData = () => {
        fetchData()
      }

      const getAllShop = async () => {
        const { data } = await getShopList(state.queryShop)
        state.shopList = data.list
        let nowCheckShopId = proxy.$route.query.shopId
        state.queryForm.shopId = nowCheckShopId
          ? Number(nowCheckShopId)
          : data.list[0]
          ? data.list[0].id
          : null
        if (state.queryForm.shopId == null) {
          state.list = []
        } else {
          fetchData()
        }
      }

      const fetchData = async () => {
        state.listLoading = true
        const { data } = await getCouponList(state.queryForm)
        state.list = data
        // state.total = data.total
        state.listLoading = false
      }

      const handleSizeChange = (val) => {
        state.queryForm.pageSize = val
      }
      const handleCurrentChange = (val) => {
        state.queryForm.pageNo = val
      }

      const shopStatusChange = async (id, status) => {
        let statusKey = status ? 'ACTIVATION' : 'OFFLINE'
        await statusShop(id, statusKey)
        fetchData()
      }
      // fetchData()

      onMounted(() => {})

      onActivated(() => {
        getAllShop()
      })

      return {
        ...toRefs(state),
        handleEdit,
        queryData,
        fetchData,
        handleSizeChange,
        handleCurrentChange,
        handleDelete,
        shopStatusChange,
        getAllShop,
        Delete,
        Search,
        Plus,
      }
    },
  })
</script>
<style lang="scss" scoped></style>
