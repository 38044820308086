import request from '@/utils/request'

/**
 * 优惠券接口
 * */

// 根据店铺id获取所有优惠券接口
export function getCouponList(params) {
  return request({
    url: '/cs/admin/coupon/select',
    method: 'get',
    params,
  })
}

// 根据店铺id 新增优惠券
export function addCoupon(data) {
  return request({
    url: '/cs/admin/coupon/add',
    method: 'post',
    data,
  })
}

// 根据店铺id 编辑优惠券
export function editCoupon(data) {
  return request({
    url: '/cs/admin/coupon/update',
    method: 'PUT',
    data,
  })
}

// 根据店铺id 删除优惠券
export function delCoupon(id) {
  return request({
    url: `/cs/admin/coupon/del/${id}`,
    method: 'DELETE',
  })
}
